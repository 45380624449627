import React, { ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { Merge } from '../../src/utils/types'

export interface CoreDividerProps {
  className?: string | undefined
  variant?: 'primary' | 'secondary' | 'standard' | 'test'
}

type DividerProps = Merge<React.ComponentProps<'div'>, CoreDividerProps>

export const Divider = forwardRef(function Divider(
  { variant = 'primary', className, ...passthroughProps }: DividerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={twMerge(
      variant === 'primary' && 'flex w-full space-x-2 justify-center my-8',
    )}>
      <div
        ref={ref}
        {...passthroughProps}
        className={twMerge(
          variant === 'primary' &&
          'w-3 h-0.5 bg-blue font-bold',
          variant === 'secondary' &&
          'h-px bg-gray-800 opacity-40',
          variant === 'standard' &&
          'h-px bg-gradient-to-r from-transparent via-blue-darker to-transparent',
          className
        )}
      />
      <div
        ref={ref}
        {...passthroughProps}
        className={twMerge(
          variant === 'primary' &&
          'w-11 h-0.5 bg-blue font-bold',
          className
        )}
      />
    </div>
  )
})
