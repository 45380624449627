import { Divider } from 'components/commons/Divider'
import Image from 'components/commons/Image'
import Icon from 'components/commons/Icon'
import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'
import Calculator from '/public/assets/img/icons/calculator.svg'
import CheckList from '/public/assets/img/icons/check-list.svg'
import CostControl from '/public/assets/img/icons/cost-control.svg'
import CurveABC from '/public/assets/img/icons/curve-abc.svg'
import Database from '/public/assets/img/icons/database.svg'
import Document from '/public/assets/img/icons/document.svg'
import Engine from '/public/assets/img/icons/engine.svg'
import ExtraWork from '/public/assets/img/icons/extra-work.svg'
import Finance from '/public/assets/img/icons/finance.svg'
import Money from '/public/assets/img/icons/money.svg'
import Planning from '/public/assets/img/icons/planning.svg'
import Ruler from '/public/assets/img/icons/ruler.svg'
import ShoppingCart from '/public/assets/img/icons/shopping-cart.svg'
import { Link } from 'components/commons/Link'

export default function Features() {
  const t = useTranslations('home/features')

  function Group({ title, subtitle, features, image, revert }: { title: string, subtitle: string, features: { name: string, description: string, icon: any, iconAlt: string, href?: string }[], image: { src: string, title: string, width: number, height: number }, revert?: boolean, last?: boolean }) {
    return (
      <div className='md:grid md:grid-cols-2 md:gap-20'>
        <div className={twMerge(revert && 'order-last')}>
          <div className='relative z-20'>
            <div className='z-20'>
              <h2 className='text-6xl font-bold md:text-8xl md:font-extrabold mb-3 max-w-3xl'>{title}</h2>
              <div className='text-sm font-bold text-blue'>{subtitle.toUpperCase()}</div>
              {features.map(({ name, description, icon, iconAlt, href }: any, index) => (
                <div key={index} className='mt-6'>
                  <Icon src={icon} alt={iconAlt} className='mb-1' />
                  {href
                    ? <Link className='ml-1 font-bold ' href={href} text={name} />
                    : <div className='ml-1 font-bold '>{name} </div>
                  }
                  <div className='ml-1 text-gray-900 text-sm z-30'>{description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='md:mt-10'>
          <Image
            href={image.src}
            width={image.width}
            height={image.height}
            title={image.title}
            priority={false}
          />
        </div>
      </div>
    )
  }

  return (
    <div id='features' className='overflow-y-hidden'>
      <div className='w-full h-full mx-auto max-w-6xl py-16 px-4'>
        <div className='text-center'>
          <label className='text-7xl font-bold tracking-tight md:text-10xl md:font-extrabold '>{t("title-1")}<br />{t("title-2")}</label>
          <Divider />
        </div>
        <div className='space-y-16'>
          <Group
            title={t("group-1-title")}
            subtitle={t("group-1-subtitle")}
            features={[
              {
                name: t("group-1-features-1-name"),
                description: t("group-1-features-1-description"),
                icon: Database,
                iconAlt: t("group-1-features-1-icon"),
                href: t("group-1-features-1-href")
              },
              {
                name: t("group-1-features-2-name"),
                description: t("group-1-features-2-description"),
                icon: Ruler,
                iconAlt: t("group-1-features-2-icon"),
                href: t("group-1-features-2-href")
              },
              {
                name: t("group-1-features-3-name"),
                description: t("group-1-features-3-description"),
                icon: CurveABC,
                iconAlt: t("group-1-features-3-icon"),
                href: t("group-1-features-3-href"),
              },
              {
                name: t("group-1-features-4-name"),
                description: t("group-1-features-4-description"),
                icon: Money,
                iconAlt: t("group-1-features-4-icon"),
                href: t("group-1-features-4-href"),
              },
              {
                name: t("group-1-features-5-name"),
                description: t("group-1-features-5-description"),
                icon: Document,
                iconAlt: t("group-1-features-5-icon")
              },
            ]}
            image={{ src: t("group-1-image-src"), width: 640, height: 640, title: t("group-1-image-title") }}
          />
          <Group
            title={t("group-2-title")}
            subtitle={t("group-2-subtitle")}
            features={[
              {
                name: t("group-2-features-1-name"),
                description: t("group-2-features-1-description"),
                icon: Calculator,
                iconAlt: t("group-2-features-1-icon"),
                href: t("group-2-features-1-href")
              },
              {
                name: t("group-2-features-2-name"),
                description: t("group-2-features-2-description"),
                icon: ShoppingCart,
                iconAlt: t("group-2-features-2-icon"),
                href: t("group-2-features-2-href")
              },
              {
                name: t("group-2-features-3-name"),
                description: t("group-2-features-3-description"),
                icon: ExtraWork,
                iconAlt: t("group-2-features-3-icon"),
                href: t("group-2-features-3-href")
              },
              {
                name: t("group-2-features-4-name"),
                description: t("group-2-features-4-description"),
                icon: CostControl,
                iconAlt: t("group-2-features-4-icon"),
                href: t("group-2-features-4-href")
              },
            ]}
            image={{ src: t("group-2-image-src"), width: 640, height: 640, title: t("group-2-image-title") }}
            revert
          />
          <Group
            title={t("group-3-title")}
            subtitle={t("group-3-subtitle")}
            features={[
              {
                name: t("group-3-features-1-name"),
                description: t("group-3-features-1-description"),
                icon: CheckList,
                iconAlt: t("group-3-features-1-icon"),
                href: t("group-3-features-1-href")
              },
              {
                name: t("group-3-features-2-name"),
                description: t("group-3-features-2-description"),
                icon: Finance,
                iconAlt: t("group-3-features-2-icon"),
                href: t("group-3-features-2-href")
              },
              {
                name: t("group-3-features-3-name"),
                description: t("group-3-features-3-description"),
                icon: Engine,
                iconAlt: t("group-3-features-3-icon"),
                href: t("group-3-features-3-href")
              },
              {
                name: t("group-3-features-4-name"),
                description: t("group-3-features-4-description"),
                icon: Document,
                iconAlt: t("group-3-features-4-icon"),
                href: t("group-3-features-4-href")
              },
              {
                name: t("group-3-features-5-name"),
                description: t("group-3-features-5-description"),
                icon: Planning,
                iconAlt: t("group-3-features-5-icon"),
                href: t("group-3-features-5-href")
              },
            ]}
            image={{ src: t("group-3-image-src"), width: 535, height: 713, title: t("group-3-image-title") }}
          />

        </div>
      </div>
    </div >
  )

}