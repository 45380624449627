import Page from 'components/Page'
import { Button } from 'components/commons/Button'
import { Divider } from 'components/commons/Divider'
import { Link } from 'components/commons/Link'
import Banner from 'components/home/Banner'
import Features from 'components/home/Features'
import Layout from 'components/layouts/Layout'
import { GetStaticProps } from 'next'
import { useTranslations } from 'next-intl'
import { ReactElement } from 'react'

export default function Home() {

  const t = useTranslations("home")

  return (
    <Page id='home' className='p-0 md:p-0' title={t("metadata-title")} description={t("metadata-description")} footer>
      <Banner />
      <div className='bg-white'>
        <Features />
      </div>
      <div className='bg-black py-16 text-center'>
        <div className='text-center max-w-4xl mx-auto'>
          <label className='text-7xl font-bold text-white md:text-10xl md:font-extrabold tracking-tight'>{t("contact-us-title")}</label>
          <Divider />
        </div>

        <Link href="/contatos" prefetch={false}>
          <Button variant='primary' text={t("contact-us-button")} />
        </Link>
      </div>
    </Page>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  return {
    props: {
      messages: (await import(`../lang/${context.locale}.json`)).default
    }
  }
}

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout>
      {page}
    </Layout>
  )
}