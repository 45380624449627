import { Button } from 'components/commons/Button'
import { Link } from 'components/commons/Link'
import Video from 'components/commons/Video'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Windows from '/public/assets/img/icons/download.svg'

const Banner = () => {
  const t = useTranslations('home/banner')

  return (
    <div id='banner' className='relative flex overflow-hidden w-100vw pt-36 pb-20 px-4 md:min-h-50vh md:pt-48 lg:min-h-hero'>
      <div className='absolute w-screen h-screen -z-10 top-0 left-0'>
        <Video
          id='0200WQhjw01Nzg4XxJOIMLc01YLjpjZF02uU02yNONSojfHVM'
          title='banner'
          className='absolute -top-12 bottom-0 right-0 min-w-full min-h-full opacity-35 mt-0 max-w-none object-contain overflow-clip w-1440 sm:w-1920'
          muted
          loop
          autoPlay
          playnInline
        />
      </div>

      <div className='flex flex-col justify-center text-center mx-auto max-w-7xl'>
        <h1 className='text-7xl font-bold text-center text-white md:text-11xl md:font-extrabold'>
          {t('title-1')}
          <strong className='text-blue lg:font-extrabold'> {t('title-2')}</strong>
        </h1>
        <p className='text-white text-center font-light text-lg mx-auto max-w-5xl mt-2 leading-8 lg:text-xl'>{t('description')}</p>
        <div className='w-full justify-center mt-8 flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4'>
          <Link
            href="/download"
            prefetch={false}
          >
            <Button
              variant='primary'
              className='inline-flex justify-center w-full lg:w-auto'
            >
              <Image alt='windows' width={30} height={30} src={Windows} className='mr-2' />
              {t('download-button')}
            </Button>
          </Link>
          <Link
            href="/planos-e-precos"
            prefetch={false}
          >
            <Button
              variant='secondary'
              className='bg-transparent border-white w-full lg:w-auto'
            >
              {t("pricing-button")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Banner
